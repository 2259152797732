import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { Button } from 'react-bootstrap';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const HSRGuidesRotaPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Daily farming route</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_rota.jpg"
            alt="Daily farming route"
          />
        </div>
        <div className="page-details">
          <h1>Daily farming route</h1>
          <h2>
            Feeling brave? The daily monster extermination route gives you a
            great way to level characters.
          </h2>
          <p>
            Last updated: <strong>10/05/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Why should you do it?" />
        <p>
          The monsters you encounter all over the overworld, don't vanish after
          you kill them for the first time and they respawn after each reset.
          This means that if you already did everything for the daily and still
          crave for more, you can do your daily monster extermination run!
        </p>
        <h6>What can you gain?</h6>
        <ul>
          <li>
            <strong>Character experience</strong> - while killing the monsters
            doesn't give Trailblaze Levels, it certainly gives character EXP.
            Which means it's a great way to level not only level your main team,
            but also later when you're much stronger, level the characters you
            didn't use before at all.
          </li>
          <ul>
            <li>
              At EQ6, a full clear gives ~104k experience (per character) and
              ~61k Credits,
            </li>
          </ul>
          <li>
            <strong>Ascension/Trace Materials</strong> - kill monster, monster
            drop loot, player happy. It's a simple philosophy and it works here
            - the amount of materials you need to level up Traces is staggering
            so this will decrease the amount of Stamina you need to waste.
          </li>
        </ul>
        <p>
          Please keep in mind that it takes <strong>roughly 2.5h</strong>{' '}
          (without Acheron) and <strong>45m</strong> (with Acheron) to run all
          the maps and kill all the monsters - so this is quite an time
          investments and we suggest doing it to those who see the value in it.
          Still, if you have Acheron, the time needed is drastically reduced and
          you can do the whole rotation in 50 minutes.
        </p>
        <SectionHeader title="Credits" />
        <p>
          Huge thanks to <strong>jolteh</strong> for preparing the massive doc
          with the routes that include TP for Acheron, so you can do the speed
          route using it. You can find the doc here:
        </p>
        <OutboundLink
          href="https://docs.google.com/document/d/1ORcXvBcmGuGqYSTSWGOBisaLjQlb2J7kBr6KSSS8KN4/edit"
          target="_blank"
        >
          <Button variant="primary">Jolt’s Farming Route</Button>
        </OutboundLink>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesRotaPage;

export const Head: React.FC = () => (
  <Seo
    title="Daily farming route | Honkai: Star Rail | Prydwen Institute"
    description="Feeling brave? The daily monster extermination route gives you a great way to level characters."
  />
);
